<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" :fill="color">
    <path d="M1.62148 0.658577C2.23786 0.224973 6.55255 0.224973 7.16894 0.658577C7.78532 1.09218 7.78532 4.12741 7.16894 4.56102C6.55255 4.99462 2.23786 4.99462 1.62148 4.56102C1.00509 4.12741 1.00509 1.09218 1.62148 0.658577Z" :fill="color"/>
    <path d="M4.08602 9.76427C4.98404 9.76427 5.71203 9.03628 5.71203 8.13825C5.71203 7.24023 4.98404 6.51224 4.08602 6.51224C3.18799 6.51224 2.46 7.24023 2.46 8.13825C2.46 9.03628 3.18799 9.76427 4.08602 9.76427Z" :fill="color"/>
    <path d="M10.5901 9.76427C11.4881 9.76427 12.2161 9.03628 12.2161 8.13825C12.2161 7.24023 11.4881 6.51224 10.5901 6.51224C9.69206 6.51224 8.96407 7.24023 8.96407 8.13825C8.96407 9.03628 9.69206 9.76427 10.5901 9.76427Z" :fill="color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.19827 5.39598C1.18325 5.18093 1.38189 5.01451 1.59483 5.04812C3.32504 5.32123 6.666 5.25178 7.36128 4.83976C7.95397 4.48854 8.12659 2.64369 7.87912 1.42247C7.83484 1.20394 7.99311 0.983453 8.21601 0.988921C9.22647 1.01371 10.688 1.12814 10.8921 1.33221C10.9575 1.39766 11.0137 1.51842 11.0607 1.67891C11.5414 1.89879 12.5554 2.87924 12.9753 3.4928C13.0601 3.52752 13.126 3.56613 13.1685 3.60864C13.4136 3.85378 13.5294 4.87503 13.5158 5.85334C14.4753 6.34935 14.375 7.99975 13.2149 8.2544C13.1915 8.25954 13.166 8.27601 13.1381 8.29755C13.0063 8.39958 12.8665 8.30497 12.8665 8.13825C12.8665 6.88102 11.8473 5.86183 10.5901 5.86183C9.33285 5.86183 8.31366 6.88102 8.31366 8.13825C8.31366 8.30954 8.18391 8.46187 8.01262 8.46251C7.5627 8.46418 7.10873 8.46364 6.6614 8.4609C6.49119 8.45986 6.36244 8.30847 6.36244 8.13825C6.36244 6.88102 5.34325 5.86183 4.08602 5.86183C2.82878 5.86183 1.80959 6.88102 1.80959 8.13825C1.80959 8.16998 1.81024 8.20155 1.81153 8.23297C1.81209 8.24664 1.79973 8.25733 1.78637 8.2544C0.728993 8.02229 0.552074 6.63056 1.25561 6.0104C1.23225 5.8149 1.21314 5.60881 1.19827 5.39598ZM11.9426 3.6561C11.9426 3.32146 10.8041 1.99682 10.4919 1.96017C10.101 1.84539 9.15636 1.86987 9.00159 2.0336C8.8196 2.22611 8.8196 3.57371 9.00159 3.76622C9.69874 4.01092 11.9426 3.82933 11.9426 3.6561Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'SplitShipmentIcon',
  props: {
    color: {
      type: String,
      default: () => '#E00000',
    },
  },
}
</script>
