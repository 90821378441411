<template>
  <a-modal
    :visible="showVisibleModal"
    class="stock-inventory-item"
    :footer="false"
    :title="null"
    @cancel="handleCancel"
  >
    <a-row>
      <a-col :span="24">
        <div class="title pbz-font subtitle-md-medium">
          Cek Stock Inventory
        </div>
        <div v-if="dataToCheckInventory" class="pbz-font caption-medium" style="color: #999999">
          catalog id: {{ dataToCheckInventory.catalog_id }}
        </div>
      </a-col>

      <template v-if="dataToCheckInventory && data.length && !loading && !showErrorMessage">
        <a-col :span="24" class="mt-5">
          <a-row>
            <a-col :span="4" class="d-flex justify-content-start align-items-center">
              <div class="image">
                <img height="49" width="54" alt="img" :src="dataToCheckInventory.image ? dataToCheckInventory.image : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" />
              </div>
            </a-col>

            <a-col :span="18" class="ml-2 align-items-center">
              <div class="title pbz-font body-md-medium">
                {{ dataToCheckInventory.title }}
              </div>
              <div class="pbz-font caption-medium" style="color: #999999">
                SKU: {{ dataToCheckInventory.sku }}
              </div>
            </a-col>
          </a-row>
        </a-col>

        <a-col :span="24" class="mt-5">
          <div v-for="(item, index) in data" :key="index" class="item-warehouse">
            <a-row>
              <a-col :span="12">
                <div class="pbz-font caption-medium" style="color: #999999">
                  Gudang
                </div>
                <div> {{ item.warehouse_name }} </div>
              </a-col>

              <a-col :span="12" class="d-flex justify-content-center align-items-center flex-column">
                <div class="pbz-font caption-medium" style="color: #999999">
                  Jumlah Stock
                </div>
                <div> {{ parseInt(item.availability, 10) }} </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </template>

      <template v-if="loading">
        <a-col :span="24" class="d-flex justify-content-center align-items-center">
          <LoadingCheckStockInvenotry class="my-5" />
        </a-col>
      </template>

      <template v-if="(showErrorMessage || (!showErrorMessage && !data.length)) && !loading">
        <a-col :span="24" class="d-flex justify-content-center align-items-center">
          <not-found-check-stock-invenotry class="my-5" />
        </a-col>
      </template>

      <a-col :span="24" class="mt-3">
        <div
          v-if="dataToCheckInventory && data.length && !loading && !showErrorMessage"
          class="pbz-font button-sm-medium d-flex justify-content-center align-items-center"
          style="
            border: 1px solid #0559CB;
            background: #0559CB;
            color: #FFFFFF;
            width: 100%;
            padding: .5rem;
            border-radius: 5px;
            cursor: pointer;
          "
          @click.prevent="handleCancel"
        >
          Selesai
        </div>

        <div
          v-if="(showErrorMessage || (!showErrorMessage && !data.length)) && !loading"
          class="pbz-font button-sm-medium d-flex justify-content-center align-items-center"
          style="
            border: 1px solid #0559CB;
            background: #0559CB;
            color: #FFFFFF;
            width: 100%;
            padding: .5rem;
            border-radius: 5px;
            cursor: pointer;
          "
          @click.prevent="fetchDataStockInventory"
        >
          Reload
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { urlToDetailFulfillment } from '@/utils/fulfillment/index'
import LoadingCheckStockInvenotry from './Loading/index.vue'
import NotFoundCheckStockInvenotry from './NotFound/index.vue'
import { getInventoryListV2 } from '@/api/inventory'
import { getWarehouseById } from '@/api/warehouse'

export default {
  components: {
    LoadingCheckStockInvenotry,
    NotFoundCheckStockInvenotry,
  },
  props: {
    dataToCheckInventory: {
      type: Object,
      default: () => null,
    },
  },
  data () {
    return {
      showVisibleModal: false,
      data: [],
      loading: false,
      showErrorMessage: false,
    }
  },
  computed: {
    getUrlToDetailFulfillment: function () {
      return urlToDetailFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
  },
  created () {
    this.showVisibleModal = true
    this.fetchDataStockInventory()
  },
  methods: {
    handleCancel () {
      this.showVisibleModal = false
      this.$emit('handleCancelModal')
    },
    async fetchDataStockInventory () {
      try {
        this.loading = true
        const { data: response } = await getInventoryListV2({
          catalog_id: this.dataToCheckInventory.catalog_id,
          business_id: this.$route.query.business_id,
        })
        const result = []

        response.forEach(async(v) => {
          const { data: { data: warehouse } } = await getWarehouseById(v.warehouse_id)

          const container = {
            ...v,
            warehouse_name: warehouse?.name || '-',
          }

          result.push(container)
        })
        this.data = result
      } catch (error) {
        this.showErrorMessage = true
        this.$notification.error({
          message: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.stock-inventory-item {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;

  .ant-modal {
    .title {
      color: #1A1A1A;
    }

    .item-warehouse {
      width: 100%;
      height: 59px;
      border: 1px solid #CCCCCC;
      border-radius: 8px;
      padding: 5px 10px;
    }
  }
}
</style>
