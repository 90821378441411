<template>
  <a-skeleton :loading="isLoading">
    <div v-if="detail" class="fulfillment-detail">
      <div v-for="(item, index) in detailFulfillments" :key="'row-1' + index">
        <a-row type="flex" justify="end">
          <a-col :span="24" class="d-flex justify-content-end align-items-center">
            <!-- <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.state?.toLowerCase())" class="action-button d-flex justify-content-center align-items-center">
              <print-label-icon class="mx-2" :color="'#E00000'" />
              <div style="color: #141414" class="mx-2 pbz-font button-sm-medium" @click="setShowModalPrintLabel(true,item)">
                {{ $t('fulfillment.printLabel') }}
              </div>
            </div>

            <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.state?.toLowerCase())" class="divider">
              <a-divider style="background: #141414 !important; font-size: 23px !important" type="vertical" />
            </div> -->

            <!-- <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.state?.toLowerCase())" class="action-button d-flex justify-content-center align-items-center" @click.prevent="handleUpdateOrder(item, FulfillmentConstant.FULFILLMENT_CHANGE_ITEM_ACTION)">
              <change-item-icon class="mx-2" :color="'#E00000'" />
              <div class="mx-2 pbz-font buttom-md-medium" style="color: #141414">
                {{ $t('fulfillment.changeItem') }}
              </div>
            </div>

            <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.state?.toLowerCase())" class="divider">
              <a-divider style="background: #141414 !important; font-size: 23px !important" type="vertical" />
            </div>

            <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.state?.toLowerCase())" class="action-button d-flex justify-content-center align-items-center" @click.prevent="handleUpdateOrder(item, FulfillmentConstant.FULFILLMENT_SPLIT_SHIPMENT_ACTION)">
              <split-shipment-icon class="mx-2" :color="'#E00000'" />
              <div class="mx-2 pbz-font buttom-md-medium" style="color: #141414">
                {{ $t('fulfillment.splitShipment') }}
              </div>
            </div> -->

            <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.delivery_order_state?.toLowerCase())" class="divider">
              <a-divider style="background: #141414 !important; font-size: 23px !important" type="vertical" />
            </div>

            <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.delivery_order_state?.toLowerCase())" class="action-button d-flex justify-content-center align-items-center" @click.prevent="handleUpdateOrder(item, FulfillmentConstant.FULFILLMENT_CANCEL_ITEM_ACTION)">
              <cancel-icon class="mx-2" :color="'#E00000'" />
              <div class="mx-2 pbz-font buttom-md-medium" style="color: #141414">
                {{ $t('fulfillment.cancelItem') }}
              </div>
            </div>

            <div v-if="permission.includes('WRITE') && !['good_issued', 'canceled'].includes(item.delivery_order_state?.toLowerCase())" class="divider">
              <a-divider style="background: #141414 !important; font-size: 23px !important" type="vertical" />
            </div>

            <div class="d-flex justify-content-center align-items-center">
              <div
                class="status"
                :style="{
                  background: getBackgroundColorStatus(item.delivery_order_state),
                }"
              >
                {{ $route.query?.delivery_order_states === 'delivered,buyer_accepted' ? 'Terkirim' : $route.query?.delivery_order_states === 'shipped' ? 'Dalam Pengiriman' : wordingFulfillmentState(item.delivery_order_state) }}
              </div>
            </div>
          </a-col>
        </a-row>

        <a-divider :key="'divider' + index" style="margin: 10px 0 !important" />

        <a-row :key="'row-2' + index">
          <a-col :span="14" class="product">
            <a-row v-for="(product, indexLineItems) in item.show_products" :key="indexLineItems" class="mb-3">
              <a-col :span="2">
                <div class="image">
                  <img height="35" width="35" alt="img" :src="product.img_url ? product.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" />
                </div>
              </a-col>

              <a-col :span="8" class="text-left">
                <div class="title pbz-font caption-medium">
                  Nama Produk
                </div>
                <div class="value pbz-font caption-medium" style="overflow-wrap: break-word;">
                  {{ product.catalog_title }}
                </div>
              </a-col>

              <a-col :span="6" class="text-center">
                <div class="title pbz-font caption-medium">
                  SKU
                </div>
                <div class="value pbz-font caption-medium" style="color: #0559CB; overflow-wrap: break-word;">
                  <a-button
                    type="link"
                    size="small"
                    class="text-dark"
                    @click="e => {
                      e.stopPropagation()
                      handleCopy('No SKU', product.sku)
                    }"
                  >
                    <a-icon type="copy" />
                  </a-button>
                  {{ product.sku }}
                </div>
              </a-col>

              <a-col :span="2" class="text-center">
                <div class="title pbz-font caption-medium">
                  Qty
                </div>
                <div class="value pbz-font caption-medium">
                  {{ product.quantity }}
                </div>
              </a-col>

              <a-col :span="3" class="text-center">
                <div class="title pbz-font caption-medium">
                  UoM
                </div>
                <div class="value pbz-font caption-medium">
                  {{ product.uom }}
                </div>
              </a-col>

              <a-col :span="3" class="text-center">
                <div class="title pbz-font caption-medium">
                  &nbsp;
                </div>
                <div class="value pbz-font caption-medium">
                  <a-button
                    v-if="bookingState[product.booking_state]"
                    shape="round"
                    size="small"
                    :class="[bookingState[item.booking_state].class, 'booking-state px-2 ml-auto w-100']"
                    style="font-size: 13px"
                  >
                    {{ bookingState[product.booking_state].title }}
                  </a-button>
                </div>
              </a-col>

              <!-- <a-col :span="6" class="text-center">
                <div class="title pbz-font caption-medium">
                  {{ $t('fulfillment.booking_inventory') }}
                </div>
                <div class="value pbz-font caption-medium">
                  {{ wordingBookingState(product.booking_state) }}
                </div>
              </a-col> -->

              <a-col :span="24" class="mt-3 mb-3 text-left d-flex align-items-center justify-content-start">
                <div class="mr-3">
                  <img src="https://ik.imagekit.io/powerbiz/img/admin-saas/Icons.svg?updatedAt=1640599594680" />
                </div>

                <div
                  style="color: #0559CB; cursor: pointer"
                  @click.prevent="handleCheckStokInventory({
                    catalog_id: product.catalog_id,
                    title: product.catalog_title,
                    sku: product.sku,
                    image: product.img_url,
                  })"
                >
                  Cek Stok Inventori
                </div>
              </a-col>

              <a-divider />
            </a-row>

            <a-row>
              <a-col v-if="item?.show_products?.length === 1 && item?.line_items?.length > 1" class="text-left" :span="24">
                <div style="color: #0559CB; cursor: pointer" @click.prevent="handleClickCollapsedProduct(true, item.id)">
                  +{{ (item.line_items.length) - 1 }} produk lainnya
                </div>
              </a-col>

              <a-col v-if="item.is_product_collapsed" class="text-left" :span="24">
                <div style="color: #0559CB; cursor: pointer" @click.prevent="handleClickCollapsedProduct(false, item.id)">
                  - uncollapsed
                </div>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="9" class="shipping-fulfillment text-left">
            <div style="color: #1A1A1A" class="pbz-font body-sm-bold">
              Pengiriman
            </div>

            <a-row class="detail" type="flex" justify="space-between">
              <a-col :span="16" class="d-flex flex-column">
                <div class="title">
                  Alamat
                </div>
                <div class="value">
                  <span> {{ item.destination.name }} </span><br />
                  <span> {{ item.destination.phone }} </span><br />
                  {{ item.destination.address.line1 }}
                  {{ item.destination.address.line2 }}
                  <!-- {{
                    [
                      item.destination.address.sub_district,
                      item.destination.address.district,
                      item.destination.address.city,
                      item.destination.address.province,
                    ].join(', ')
                  }} - {{ item.destination.address.country }}, {{ item.destination.address.postal_code }} -->
                </div>

                <div v-if="['DELIVERED', 'BUYER_ACCEPTED'].includes(item.delivery_order_state)" class="mt-2 ">
                  <div class="status text-center" :style="{color: '#fff', background: isAccepted(item.delivery_order_state) ? '#2D9500' :'#999999'}">
                    {{ isAccepted(item.delivery_order_state) ? 
                      'Buyer Sudah melakukan konfirmasi pesanan diterima' :
                      'Buyer belum melakukan konfirmasi pesanan diterima'
                    }}
                  </div>
                </div>
              </a-col>

              <a-col :span="6" class="d-flex flex-column">
                <div class="mb-3">
                  <div class="title">
                    Kurir
                  </div>
                  <div class="value pbz-font buton-sm-medium">
                    {{ item.shipping.courier ? item.shipping.courier : '-' }}
                  </div>
                </div>

                <div class="mb-3">
                  <div class="title">
                    No. Resi
                  </div>
                  <div class="value pbz-font buton-sm-medium" style="color: #0559CB; overflow-wrap: break-word;">
                    {{ item.shipping.awb || item.shipping.booking_no || '-' }}
                  </div>
                </div>

                <!-- <div class="mb-3">
                  <div class="title"> Dikirim Dari </div>
                  <div class="value pbz-font buton-sm-medium">
                    Jakarta
                  </div>
                </div> -->

                <div class="mb-1">
                  <div class="title">
                    COD
                  </div>
                  <div class="value pbz-font buton-sm-medium">
                    {{ item.shipping.is_cod === true ? 'YA' : item.shipping.is_cod === false ? 'TIDAK' : '-' }}
                  </div>
                </div>
              </a-col>
            </a-row>

            <!-- RIWAYAT PENGIRIMAN ON HOLD API NOT READY-->
            <!-- <div class="mt-3 d-flex">
              <div class="mr-3">
                <img src="https://ik.imagekit.io/powerbiz/img/admin-saas/Icons_1_.svg?updatedAt=1640600663581">
              </div>

              <div style="color: #0559CB; cursor: pointer">
                Riwayat Pengiriman
              </div>
            </div> -->
          </a-col>
        </a-row>

        <template>
          <fragment :key="'rotuer-view' +index">
            <router-view
              :key="'rotuer-view' +index"
              :order="detail"
              :detail-fulfillment="item"
              :data-to-check-inventory="dataToCheckInventory"
            />
          </fragment>
        </template>

        <a-divider :key="'divider-1' + index" />
      </div>

      <!-- Modal Confirmation Print Label -->
      <a-modal
        :visible="showModalPrintLabel"
        :footer="null"
        class="modal-accept-order"
        @cancel="() => setShowModalPrintLabel(false)"
      >
        <a-row>
          <a-col :span="24" class="d-flex justify-content-center">
            <div class="pbz-font subtitle-md-medium text-confirmation">
              Print Label Pesanan
            </div>
          </a-col>

          <a-col :span="24" class="mt-3 d-flex justify-content-center">
            <div class="pbz-font text-caption">
              <div style="color: #999999">
                Cetak alamat pengiriman disertai dengan detail barang?
              </div>
            </div>
          </a-col>

          <a-col :span="24" class="mt-5 d-flex justify-content-center align-items-center">
            <div
              class="save pbz-font button-sm-medium d-flex justify-content-center align-items-center"
              style="
                border: 1px solid #E00000;
                background: #E00000;
                color: #FFFFFF;
                width: 100%;
                padding: .75rem;
                border-radius: 5px;
                cursor: pointer;
              "
              @click="toLabelPage(true)"
            >
              <div>
                <div>Ya, dengan detail barang</div>
              </div>
            </div>
          </a-col>

          <a-col :span="24" class="d-flex justify-content-center align-items-center mt-2">
            <a-button
              class="cancel pbz-font button-sm-medium"
              style="
                width: 100%;
                height: 100%;
                color: #E00000;
                padding: .75rem;
                cursor: pointer;
                border: 1px solid #E00000;
                border-radius: 5px;
              "
              @click.prevent="toLabelPage(false)"
            >
              Tidak, hanya alamat saja
            </a-button>
          </a-col>
        </a-row>
      </a-modal>

      <!-- Print Label -->
      <CheckStockInventoryMoadal v-if="showModalStockInventory" :data-to-check-inventory="dataToCheckInventory" @handleCancelModal="() => (showModalStockInventory = false) " />

      <div v-if="showModalUpdateFulfillment">
        <ModalUpdateOrder 
          :order="detail"
          :detail-fulfillment="detailFulfillments"
          @setShowModalUpdateFulfillment="setShowModalUpdateFulfillment"
        />
        <!-- <router-view
        /> -->
      </div>
    </div>
  </a-skeleton>
</template>

<script>
import { getFulfillmentDetail } from '@/api/fulfillment'
import { resynchronizeOrder } from '@/api/channels/index'
import { setBackgroundColorStatus, setWordingFulfillmentState, setWordingBookingState, getUrlToUpdateOrderFromFulfillmentDetailPage } from '@/utils/fulfillment/index'

import CheckStockInventoryMoadal from '@/components/Fulfillment/CheckStockInventory/index.vue'

import PrintLabelIcon from '@/components/Icons/PrintLabel.vue'
import CancelIcon from '@/components/Icons/CancelIcon.vue'
import SplitShipmentIcon from '@/components/Icons/SplitShipment.vue'
import ChangeItemIcon from '@/components/Icons/ChangeItemIcon.vue'
import ModalUpdateOrder from '@/components/Fulfillment/UpdateOrder/index.vue'

import FulfillmentConstant from '@/constants/fulfillment'
import getQueryParam from '@/utils/getQueryParam'

export default {
  components: {
    CheckStockInventoryMoadal,
    CancelIcon,
    SplitShipmentIcon,
    ChangeItemIcon,
    PrintLabelIcon,
    ModalUpdateOrder,
  },
  mixins: [getQueryParam],
  props: {
    fulfillment_id: {
      type: String,
    },
  },
  data: function () {
    return {
      isLoading: false,
      detail: null,
      dataToCheckInventory: {},
      showModalPrintLabel: false,
      detailLabel: null,
      showModalStockInventory: false,
      showModalUpdateFulfillment: false,
      FulfillmentConstant,
      flagResync: false,
      bookingState: {
        BOOKED: {
          title: 'Allocated',
          class: 'allocated-state',
        },
        ALLOCATED: {
          title: 'Allocated',
          class: 'allocated-state',
        },
        SUCCESS_ALLOCATED: {
          title: 'Allocated',
          class: 'allocated-state',
        },
        ALLOCATION_RELEASED: {
          title: 'Allocated',
          class: 'allocated-state',
        },
        NOT_YET_ALLOCATED: {
          title: 'Unallocated',
          class: 'unallocated-state',
        },
        ALLOCATION_FAILED: {
          title: 'OOS',
          class: 'oos-state',
        },
        OUT_OF_STOCK: {
          title: 'OOS',
          class: 'oos-state',
        },
        OOS: {
          title: 'OOS',
          class: 'oos-state',
        },
      },
    }
  },
  computed: {
    detailFulfillments: function () {
      if (!this.detail) return []

      return this.detail.fulfillments.map(item => ({...item, order: this.detail.order}))
    },
    urlToUpdateOrder: function () {
      return getUrlToUpdateOrderFromFulfillmentDetailPage({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
    isMasterFulfillment: function () {
      return this.$route.meta.key === 'fulfillment' || this.$route.meta.key === 'fulfillment-detail'
    },
    permission() {
      return this.$store.getters['user/can']('fulfillment')
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.$route.name === 'fulfilment-edit-order' && (this.showModalUpdateFulfillment === false)) {
          this.showModalUpdateFulfillment = true
        } else {
          this.showModalUpdateFulfillment = false
        }
      },
    },
    '$route.query.fulfillment_id': {
      immediate: true,
      handler: function (newVal, oldVal) {
        const fulfillment_ids = oldVal
          ? oldVal.split(',')
          : []
        if(!fulfillment_ids.length || !fulfillment_ids.includes(this.fulfillment_id)) {
          this.fetchDetailFulfillmentBasedFulfillmentId()
        }
      },
    },
  },
  methods: {
    fetchDetailFulfillmentBasedFulfillmentId: async function () {
      this.isLoading = true
      await getFulfillmentDetail({
        url: 'fulfillment',
        fulfillment_id: this.fulfillment_id,
      })
      .then(({ data: { data: response } }) => {
        this.detail = response
        if (response.order.channel.code === 'tokopedia_id') {
          if ((!response?.fulfillments?.[0]?.shipping.awb || 
              !response?.fulfillments?.[0]?.shipping.courier || 
              !response?.fulfillments?.[0]?.shipping.shipping_label_url) && !this.flagResync) {
            this.resychronizeShipping(response.order.id, response.order.channel.code)
          } else {
            if (Array.isArray(this.detail.fulfillments)) {
              this.detail.fulfillments = this.detail.fulfillments.map((item) => {
                return {
                  ...item,
                  show_products: [item.line_items[0]],
                  is_product_collapsed: false,
                }
              })
            }
            this.flagResync = false
          }
        } else {
          if (Array.isArray(this.detail.fulfillments)) {
            this.detail.fulfillments = this.detail.fulfillments.map((item) => {
              return {
                ...item,
                show_products: [item.line_items[0]],
                is_product_collapsed: false,
              }
            })
          }
        }
      })
      .catch(err => console.error(err))
      .finally(() => this.isLoading = false)
    },
    resychronizeShipping: async function (order_id, channelCode) {
      this.isLoading = true
      await resynchronizeOrder({
        business_id: this.$route.query.business_id,
        channel_code: channelCode,
        order_id: order_id,
      })
      .then(() => this.isLoading = true)
      .catch(err => {
          console.error('errornya', err)
          this.isLoading = true
        })
      .finally(() => {
        this.flagResync = true
        setTimeout(() => {
          this.fetchDetailFulfillmentBasedFulfillmentId()
        }, 3000)
      })
    },
    handleClickCollapsedProduct: function (state) {
      this.detail.fulfillments = this.detail.fulfillments.map(item => ({
        ...item,
        show_products: state ? item.line_items : [item.line_items[0]],
        is_product_collapsed: state,
      }))
    },
    handleUpdateOrder: function (value, action) {
      const yAxis = window.top.scrollY
      this.$router.push({
        path: this.urlToUpdateOrder,
        query: { ...this.$route.query, fulfillment_id_to_update: value.id, actionUpdate: action },
      }).then(() => window.scrollTo(0, yAxis))
    },
    wordingBookingState: function (item) {
      return setWordingBookingState(item)
    },
    getBackgroundColorStatus (item) {
      return setBackgroundColorStatus(item)
    },
    wordingFulfillmentState: function (item, state) {
      return setWordingFulfillmentState(item, state)
    },
    handleCheckStokInventory: function ({ catalog_id, title, sku, image }) {
      this.dataToCheckInventory = { catalog_id, title, sku, image }
      this.showModalStockInventory = true
    },
    setShowModalPrintLabel (param, item) {
      this.showModalPrintLabel = param
      this.detailLabel = item
    },
    toLabelPage (param) {
      this.$router.push({
        // name: this.$route.params.id === undefined ? 'fulfillment-label' : 'channel-fulfillment-label',
        name: 'fulfillment-label',
        query: {
          ...this.getQueryParam(this.$route.query),
          order_id: this.detail.order.id,
          fulfillment_id: this.detailLabel.id,
        },
        params: {
          label_product: param,
        },
      })
    },
    setShowModalUpdateFulfillment (param) {
      this.showModalUpdateFulfillment = param
    },
    isAccepted(param) {
      return param === 'BUYER_ACCEPTED'
    },
    handleCopy(type, val) {
      navigator.clipboard.writeText(val)
      this.$notification.success({
        message: `Copied ${type}: ${val}`,
      })
    },
  },
}
</script>

<style lang="scss">
.fulfillment-detail {
  padding: 10px;
  // background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  cursor: default;

  .action-button {
    cursor: pointer;
  }

  .product, .shipping {
    padding: 10px;
  }

  .shipping-fulfillment {
    margin-left: 2rem;

    .detail {
      margin-top: 15px;

      .title {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #999999;
        margin-bottom: .3rem;
      }

      .value {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #1A1A1A;
        line-height: 25px;
        inline-size: 250px;
        overflow-wrap: break-word;
        span {
          font-weight: 600;
          line-height: 25px;
        }
      }
    }
  }

  .product {
    border-right: 1px solid #CCCCCC;

    .image {
      border: 0.666667px solid #CCCCCC;
      box-sizing: border-box;
      border-radius: 1.66667px;
      width: 40px;
      height: 40px;
    }

    .title {
      color: #999999;
    }

    .value {
      color: #1A1A1A;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: fit-content;
    box-shadow: 0px 5px 5.3625px rgba(0, 0, 0, 0.055);
    border-radius: 33px;
    color: #ffffff;
  }

  .cancel-button {
    line-height: 21px;
    color: #0559cb;
  }

  .accept-button {
    border: 1px solid #0559cb;
    border-radius: 5px;
    color: #0559cb;
  }
}
</style>
