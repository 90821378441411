<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" :fill="color">
    <path d="M5.4344 5.81587C5.98098 5.81587 8.16732 3.62287 8.16732 3.07462C8.16732 2.52637 5.98098 0.333374 5.4344 0.333374C5.27712 0.333374 5.1651 0.787314 5.09833 1.43395C2.72656 1.53709 0.833984 3.58221 0.833984 6.08999C0.833984 6.90326 1.03303 7.66787 1.3829 8.33337C1.59387 6.25432 3.11107 4.5835 5.05916 4.23713C5.11554 5.12674 5.24062 5.81587 5.4344 5.81587Z" :fill="color"/>
    <path d="M8.90024 8.18422C8.35365 8.18422 6.16732 10.3772 6.16732 10.9255C6.16732 11.4737 8.35365 13.6667 8.90024 13.6667C9.05752 13.6667 9.16954 13.2128 9.2363 12.5661C11.6081 12.463 13.5007 10.4179 13.5007 7.91009C13.5007 7.09682 13.3016 6.33221 12.9517 5.66671C12.7408 7.74577 11.2236 9.41658 9.27548 9.76295C9.2191 8.87334 9.09402 8.18422 8.90024 8.18422Z" :fill="color"/>
    <path d="M9.16732 0.666707C9.61176 0.222263 12.7229 0.222263 13.1673 0.666707C13.6118 1.11115 13.6118 4.22226 13.1673 4.66671C12.7229 5.11115 9.61176 5.11115 9.16732 4.66671C8.72287 4.22226 8.72287 1.11115 9.16732 0.666707Z" :fill="color"/>
    <path d="M1.16732 13.3334C1.61176 13.7778 4.72287 13.7778 5.16732 13.3334C5.61176 12.8889 5.61176 9.77782 5.16732 9.33337C4.72287 8.88893 1.61176 8.88893 1.16732 9.33337C0.722873 9.77782 0.722873 12.8889 1.16732 13.3334Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'ChangeItemIcon',
  props: {
    color: {
      type: String,
      default: () => '#E00000',
    },
  },
}
</script>
