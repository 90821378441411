<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" :fill="color">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50065 13.6667C11.1825 13.6667 14.1673 10.6819 14.1673 7.00004C14.1673 3.31814 11.1825 0.333374 7.50065 0.333374C3.81875 0.333374 0.833984 3.31814 0.833984 7.00004C0.833984 10.6819 3.81875 13.6667 7.50065 13.6667ZM3.75534 3.25473C4.09493 2.91514 4.64551 2.91514 4.98509 3.25473L7.50065 5.77029L10.0162 3.25473C10.3558 2.91514 10.9064 2.91514 11.246 3.25473C11.5855 3.59432 11.5855 4.1449 11.246 4.48448L8.7304 7.00004L11.246 9.5156C11.5855 9.85519 11.5855 10.4058 11.246 10.7454C10.9064 11.0849 10.3558 11.0849 10.0162 10.7454L7.50065 8.22979L4.98509 10.7454C4.64551 11.0849 4.09493 11.0849 3.75534 10.7454C3.41575 10.4058 3.41575 9.85519 3.75534 9.5156L6.2709 7.00004L3.75534 4.48448C3.41575 4.1449 3.41575 3.59432 3.75534 3.25473Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'ColorIcon',
  props: {
    color: {
      type: String,
      default: () => '#E00000',
    },
  },
}
</script>
